export enum RoundMode {
  OFF,
  UP,
  DOWN,
}

export const roundFloat = (num: number, digits: number, mode = RoundMode.OFF): number => {
  const mult = parseInt(`1${"0".repeat(digits)}`);
  const multipliedNum = Math.abs(num * mult);
  // @ts-expect-error TS7006: Parameter 'result' implicitly has an 'any' type.
  const finishResult = result => (result / mult) * (num < 0 ? -1 : 1);
  switch (mode) {
    case RoundMode.UP:
      return finishResult(Math.ceil(multipliedNum));
    case RoundMode.DOWN:
      return finishResult(Math.floor(multipliedNum));
    default:
      return finishResult(Math.round(multipliedNum));
  }
};

export const millionToDecimal = (n: number, digits = 0): number =>
  roundFloat(Number(Math.abs(Number(n)) / 1.0e6), digits, RoundMode.DOWN);
