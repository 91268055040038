import NextImage from "@components/NextImage";
import {Trans, useTranslation} from "ni18n";
import React, {useMemo} from "react";
import useMediaQuery from "src/useMediaQuery";

import {useFetchCareEntries} from "../../../queries/useFetchCareEntries";
import {useFeaturedCareSlugs} from "../../../utils/getFeaturedCares";
import {CareEntryRowVariant} from "../CareEntryRow";
import {useSendDefaultResultsViewedEvent} from "../Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import CareDiscoverySearch from "../Reason/CareDiscoverySearch/CareDiscoverySearch";

export const Hero: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();

  const featuredCareSlugs = useFeaturedCareSlugs();
  const defaultResultsQuery = useFetchCareEntries(featuredCareSlugs);

  const isHSm = useMediaQuery("md", {isHeight: true});
  const isWXl = useMediaQuery("xl");
  const is13inchScreen = isHSm && isWXl;

  const viewId = useSendDefaultResultsViewedEvent({
    results: defaultResultsQuery.data,
    isLoading: !defaultResultsQuery.isSettled,
  });

  const widget = useMemo(
    () => (
      <div className="md:bg-white md:rounded-xl md:p-6 flex flex-col p-4 pt-6 md:justify-between md:min-w-[27rem]">
        <CareDiscoverySearch
          defaultResults={defaultResultsQuery.data}
          defaultResultsLoadingCount={
            !defaultResultsQuery.isSettled ? featuredCareSlugs.length : undefined
          }
          dialogTitle={i18n.t("Book an Appointment")}
          defaultListRowVariant={CareEntryRowVariant.SM}
          viewId={viewId}
          hideRegionSelector
          hideDefaultListController
          hideDefaultListGradient
          doNotReportDefaultResultsOnMount
        />
      </div>
    ),
    [
      defaultResultsQuery.data,
      defaultResultsQuery.isSettled,
      featuredCareSlugs.length,
      i18n,
      viewId,
    ],
  );

  return (
    <>
      <div
        className={`${is13inchScreen ? "ph12" : "ph8"} pt36-md pb40 pb18-md pos-r contrast-tbb ${
          is13inchScreen ? "pt20" : "pt40"
        }`}
      >
        <NextImage
          src="home/hero"
          quality="80"
          priority
          objectFit="cover"
          objectPosition="0 0"
          layout="fill"
          className="obpl contrast-op0"
          alt=""
        />
        <div className="df maxw85 ma jcsb aife pos-r">
          <div>
            <h1 className="white maxw30 mb2 lh12 font-cm fs24-md">
              <Trans>
                Making good health accessible <em className="lightYellow fsn">to all.</em>
              </Trans>
            </h1>
            <p className="fs28 fs18-md white font-cm lh14">
              {i18n.t("Book same day appointments.")}
            </p>
            <p className="fs28 fs18-md white font-cm mb8 lh14">{i18n.t("No subscriptions.")}</p>
          </div>
          <div className="hidden md:block w-[460px]">{widget}</div>
        </div>
      </div>
      <div className="md:hidden">{widget}</div>
    </>
  );
};

export default React.memo(Hero);
